import React from 'react';
import { SparklesCore } from '../../../AceternityComponents/ui/sparkles.tsx';
import { FlipWords } from '../../../AceternityComponents/ui/flip-words.tsx';
import Spline from '@splinetool/react-spline';
import RobotFile1 from '../../../assets/3DModels/robot1.splinecode'; // moved right
import RobotFile2 from '../../../assets/3DModels/robot2.splinecode'; // Centered
import { useMediaQuery } from '@chakra-ui/react';
const words = [
  'Internet of Things (IoT)',
  'Artificial Intelligence (AI)',
  'Automation Technology',
  'Fullstack SaaS Development',
];

export default function Section1() {
  const [isLargerThan800] = useMediaQuery('(min-width: 640px)');

  return (
    <>
      <div style={{ position: 'relative' }}>
        {/* <Spline
          scene={isLargerThan800 ? RobotFile1 : RobotFile2}
          // scene="https://prod.spline.design/LfodCcR1MBUNAYiJ/scene.splinecode"
          style={{
            position: 'absolute',
            zIndex: isLargerThan800 ? '100' : '0',
            right: '0',
            top: isLargerThan800 ? '0' : '-2rem',
            pointerEvents: 'all',
          }}
        /> */}

        <div className="w-full bg-black">
          <div className="h-[100vh] w-[100%] md:w-[60%] bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
            <h1 className="md:text-6xl text-6xl lg:text-8xl mt-24 md:mt-0 font-bold text-center text-white relative z-20">
              MYACCESS
            </h1>
            <h1 className="md:text-2xl text-xl lg:text-3xl font-bold text-center text-white relative z-20">
              IoT Automation & Maker Zone
            </h1>

            <div className="w-[40rem] h-40 relative md:mb-48">
              {/* Gradients */}
              <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
              <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
              <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
              <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

              {/* Core component */}
              <SparklesCore
                background="transparent"
                minSize={0.4}
                maxSize={1}
                particleDensity={1800}
                className="w-full h-[2rem] md:h-full bg-transparent"
                particleColor="#FFFFFF"
              />

              {/* Radial Gradient to prevent sharp edges */}
              <div className="absolute inset-0 w-full h-full bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
              <h1 className="mt-2 mb-4 md:text-xl text-l lg:text-2xl font-bold text-center text-white relative z-20">
                Explore innovations with live project demos
                <br />
                <FlipWords words={words} className="text-white" />
              </h1>
              <div
                className="flex justify-center align-center"
                style={{
                  zIndex: '101',
                  cursor: 'pointer',
                }}
              >
                <button
                  onClick={() => {
                    alert('Coming soon');
                  }}
                  style={{
                    position: 'relative',
                    zIndex: '101',
                  }}
                  className="w-40 h-10 mr-6 font-bold rounded-xl  border border-white border-transparent  text-sm bg-white text-black"
                >
                  View Demos
                </button>
                <button
                  style={{
                    position: 'relative',
                    zIndex: '101',
                  }}
                  className="z-101 w-40 h-10 font-bold rounded-xl bg-black border border-white border-transparent text-white text-sm hover:bg-white hover:text-black"
                >
                  Contact now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
