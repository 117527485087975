import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import AuroraBackground from '../../AceternityComponents/ui/aurora-background.tsx';
import Section1 from './Sections/Section1.jsx';
import Section2 from './Sections/Section2.jsx';
import Section3 from './Sections/Section3.jsx';
import { FloatButton } from 'antd';
import { MoonOutlined, SunOutlined } from '@ant-design/icons';

import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
export default function Landing() {
  // Update theme based on `darkMode` state
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <>
      <Section1 />
      <Section3 />
      <Section2 />
      <FloatButton
        icon={!darkMode ? <MoonOutlined /> : <SunOutlined />}
        type={!darkMode ? 'default' : 'primary'}
        style={{
          insetInlineEnd: 24,
          bottom: 24,
        }}
        onClick={() => {
          toggleColorMode();
          toggleDarkMode();
          navigate(`#theme=${colorMode === 'light' ? 'dark' : 'light'}`);
        }}
      />
    </>
  );
}
