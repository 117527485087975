// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
function StartFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyBDWs50A9ckuBPQl2y0tGb1LGoq8xa97Kw',
    authDomain: 'automationv1-fb3b2.firebaseapp.com',
    databaseURL: 'https://automationv1-fb3b2-default-rtdb.firebaseio.com',
    projectId: 'automationv1-fb3b2',
    storageBucket: 'automationv1-fb3b2.firebasestorage.app',
    messagingSenderId: '1058541100287',
    appId: '1:1058541100287:web:7d06e82ce706b53b8880ca',
    measurementId: 'G-TP39R91YPJ',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return getDatabase(app);
}
export default StartFirebase;
