// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useState, useEffect } from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import StartFirebase from '../../../firebase/index';
import {
  ref,
  set,
  get,
  update,
  remove,
  child,
  onValue,
} from 'firebase/database';
import { Spin, Switch } from 'antd';
import Card from 'components/card/Card.js';
import ReactPlayer from 'react-player';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
export default function UserReports() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  const [SpinnerLoading, setSpinnerLoading] = useState(true);
  const [SwitchLoading, setSwitchLoading] = useState({
    Charge: false,
    Discharge: false,
    ReadIRV: false,
  });
  const db = StartFirebase();

  const [DisplayData, setDisplayData] = useState({
    BusVoltage: 0.0,
    Current: 0,
    IRV: 0,
    IRVLastCheck: 0,
    LoadVoltage: 0,
    Power: 0,
    ShuntVoltage: 0,
    timestamp: 0,
  });
  const [TriggerData, setTriggerData] = useState({
    Charge: false,
    Discharge: false,
    ReadIRV: false,
  });

  function FormatData(value, decimal) {
    return parseFloat(value ? value : 0).toFixed(decimal);
  }

  function FindState(value, decimal) {
    let Current = parseFloat(value ? value : 0).toFixed(decimal);
    let State = '';
    if (Current == 0.0) {
      State = 'Idle';
    } else if (Current > 0.0) {
      State = 'Charging';
    } else if (Current < 0.0) {
      State = 'Discharging';
    } else {
    }
    return State;
  }

  function dataFetch() {
    const dbRef = ref(db, 'BMSV1/');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach((childSnapshot) => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({ key: keyName, data: data });
      });
      setDisplayData(records[0]?.data);
      setTriggerData(records[1]?.data);
      console.log('Display Data: ', records);
      setSpinnerLoading(false);
    });
  }
  useEffect(() => {
    dataFetch();
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name="Voltage"
            value={`${FormatData(DisplayData?.BusVoltage, 4)} V`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Current"
            value={`${FormatData(DisplayData?.Current, 2)} mA`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Wattage"
            value={`${(
              FormatData(DisplayData?.BusVoltage, 4) *
              FormatData(DisplayData?.Current, 2)
            ).toFixed(2)} W`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Percentage"
            value={`${FormatData(DisplayData?.Percentage, 0)} %`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Internal Resistance of the Cell"
            value={`${FormatData(DisplayData?.IRV * 1000, 2)} mΩ`}
            growth="At 6th Jan 2024"
          />

          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
              />
            }
            name="Status"
            value={`${FindState(DisplayData?.Current, 2)}`}
          />
        </SimpleGrid>

        {/* To Control */}
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            '2xl': 'repeat(6, 1fr)',
          }}
          gap={{ base: '0', md: '0', lg: '20px' }}
          mb="20px"
        >
          <GridItem colSpan={{ md: 2, lg: 1 }}>
            <Grid
              templateColumns={{
                base: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              gap="20px"
              mb="20px"
            >
              {/* <GridItem colSpan={{ base: 2, md: 1, lg: 1 }}> */}
              <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
                <Card py="15px">
                  <Flex
                    my="auto"
                    h="100%"
                    align={{ base: 'center', xl: 'start' }}
                    justify={{ base: 'center', xl: 'center' }}
                  >
                    <Stat my="auto">
                      <StatLabel
                        lineHeight="100%"
                        color={textColorSecondary}
                        fontSize={{
                          base: 'sm',
                        }}
                      >
                        Cell Charge Control
                      </StatLabel>
                      <StatNumber
                        color={textColor}
                        fontSize={{
                          base: 'xl',
                        }}
                      >
                        Charging
                      </StatNumber>
                    </Stat>
                  </Flex>
                  <Flex my="auto" justify={{ base: 'center', xl: 'center' }}>
                    <Switch
                      onChange={(e) => {
                        setSwitchLoading({ ...SwitchLoading, Charge: true });
                        console.log(e);
                        update(ref(db, 'BMSV1/LiveStream/'), {
                          Charge: `${e}`,
                        });
                        setSwitchLoading({ ...SwitchLoading, Charge: false });
                      }}
                      value={TriggerData.Charge === 'true' ? true : false}
                      checkedChildren={'ON'}
                      unCheckedChildren={'OFF'}
                      style={{ transform: 'scale(1.7)', margin: '1.5rem 0' }}
                      loading={SwitchLoading.Charge}
                    />
                  </Flex>
                </Card>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
                <Card py="15px">
                  <Flex
                    my="auto"
                    h="100%"
                    align={{ base: 'center', xl: 'start' }}
                    justify={{ base: 'center', xl: 'center' }}
                  >
                    <Stat my="auto">
                      <StatLabel
                        lineHeight="100%"
                        color={textColorSecondary}
                        fontSize={{
                          base: 'sm',
                        }}
                      >
                        Cell Discharge Control
                      </StatLabel>
                      <StatNumber
                        color={textColor}
                        fontSize={{
                          base: 'xl',
                        }}
                      >
                        Discharging
                      </StatNumber>
                    </Stat>
                  </Flex>
                  <Flex my="auto" justify={{ base: 'center', xl: 'center' }}>
                    <Switch
                      onChange={(e) => {
                        setSwitchLoading({ ...SwitchLoading, Discharge: true });
                        console.log(e);
                        update(ref(db, 'BMSV1/LiveStream/'), {
                          Discharge: `${e}`,
                        });
                        setSwitchLoading({
                          ...SwitchLoading,
                          Discharge: false,
                        });
                      }}
                      value={TriggerData.Discharge === 'true' ? true : false}
                      checkedChildren={'ON'}
                      unCheckedChildren={'OFF'}
                      style={{ transform: 'scale(1.7)', margin: '1.5rem 0' }}
                      loading={SwitchLoading.Discharge}
                    />
                  </Flex>
                </Card>
              </GridItem>

              <GridItem colSpan={{ base: 2, md: 2, lg: 2 }}>
                <Card py="15px">
                  <Flex
                    my="auto"
                    h="100%"
                    align={{ base: 'center', xl: 'start' }}
                    justify={{ base: 'center', xl: 'center' }}
                  >
                    <Stat my="auto">
                      <StatLabel
                        lineHeight="100%"
                        color={textColorSecondary}
                        fontSize={{
                          base: 'sm',
                        }}
                      >
                        Measurement
                      </StatLabel>
                      <StatNumber
                        color={textColor}
                        fontSize={{
                          base: 'xl',
                        }}
                      >
                        Internal Resistance
                      </StatNumber>
                    </Stat>
                  </Flex>
                  <Flex my="auto" justify={{ base: 'center', xl: 'center' }}>
                    <Switch
                      checkedChildren={'Trigger'}
                      unCheckedChildren={'Idle'}
                      defaultChecked
                      onChange={(e) => {
                        setSwitchLoading({ ...SwitchLoading, ReadIRV: true });
                        console.log(e);
                        update(ref(db, 'BMSV1/LiveStream/'), {
                          ReadIRV: `${e}`,
                        });
                        setSwitchLoading({ ...SwitchLoading, ReadIRV: false });
                      }}
                      value={TriggerData.ReadIRV === 'true' ? true : false}
                      loading={SwitchLoading.ReadIRV}
                      style={{ transform: 'scale(1.7)', margin: '1.5rem 0' }}
                    />
                  </Flex>
                </Card>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem colSpan={2}>
            <Card py="15px">
              {/* <iframe
                src={'http://192.168.1.132'}
                width="100%"
                height="500px"
                style={{ border: 'none' }}
                title="Embedded Page"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              />{' '} */}
              <iframe
                src={'http://192.168.1.132'}
                width="100%"
                height="500px"
                style={{ border: 'none' }}
                title="Embedded Page"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              />{' '}
              {/* <ReactPlayer
                url={'rtsp://192.168.35.112'}
                playing
                controls
                width="100%"
                height="500px"
              /> */}
            </Card>
          </GridItem>
        </Grid>

        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <TotalSpent />
          <WeeklyRevenue />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
            <DailyTraffic />
            <PieCard />
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
          />
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
            <Tasks />
            <MiniCalendar h="100%" minW="100%" selectRange={false} />
          </SimpleGrid>
        </SimpleGrid> */}
      </Box>
      <Spin spinning={SpinnerLoading} fullscreen />
    </>
  );
}
