// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes.js';
import { FloatButton } from 'antd';
import { MoonOutlined, SunOutlined } from '@ant-design/icons';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// Custom Chakra theme

// Pages

import BMS from 'views/projects/BMS';

export default function Dashboard(props) {
  // Update theme based on `darkMode` state
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  document.documentElement.dir = 'ltr';
  return (
    <>
      <Box>
        <Box>
          <SidebarContext.Provider
            value={{
              toggleSidebar,
              setToggleSidebar,
            }}
          >
            <Box
              float="right"
              minHeight="100vh"
              height="100%"
              overflow="auto"
              position="relative"
              maxHeight="100%"
              w={{ base: '100%' }}
              maxWidth={{ base: '100%' }}
              transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              transitionTimingFunction="linear, linear, ease"
            >
              <Portal>
                <Box>
                  <Navbar
                    onOpen={onOpen}
                    logoText={'Horizon UI Dashboard PRO'}
                    brandText={`BMS Monitoring of the cells`}
                    fixed={fixed}
                    {...rest}
                  />
                </Box>
              </Portal>
              <Box
                mx="auto"
                p={{ base: '20px', md: '30px' }}
                pe="20px"
                minH="100vh"
                pt="50px"
              >
                <Routes>
                  <Route path="/BMS" element={<BMS />} />
                  <Route path="/*" element={<Navigate to="/" replace />} />
                </Routes>
              </Box>
              <Box>
                <Footer />
              </Box>
            </Box>
          </SidebarContext.Provider>
        </Box>
      </Box>
      <FloatButton
        icon={!darkMode ? <MoonOutlined /> : <SunOutlined />}
        type={!darkMode ? 'default' : 'primary'}
        style={{
          insetInlineEnd: 24,
          bottom: 24,
        }}
        onClick={() => {
          toggleColorMode();
          toggleDarkMode();
          navigate(`#theme=${colorMode === 'light' ? 'dark' : 'light'}`);
        }}
      />
    </>
  );
}
