import React from 'react';
import { useState } from 'react';
import { Lens } from '../../../AceternityComponents/ui/lens.tsx';
import { motion } from 'framer-motion';
import { Spotlight } from '../../../AceternityComponents/ui/spotlight.tsx';
import { FlipWords } from '../../../AceternityComponents/ui/flip-words.tsx';
import { Rays, Beams } from '../Components/LensComponents.jsx';
import { Image } from 'antd';
import ProjectImages from '../data/ProjectImages.jsx';
export default function Section3() {
  const [hovering, setHovering] = useState(false);
  const [hovering2, setHovering2] = useState(false);

  const words = [
    'voltage',
    'current',
    'wattage',
    'percentage',
    'charge & discharge state',
    'internal resistance',
  ];
  const words2 = ['temperature', 'humidity', 'light intensity'];
  return (
    <>
      <div className="h-[20rem] w-full  flex md:items-center md:justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          fill="white"
        />
        <div className=" p-4 max-w-7xl  mx-auto relative z-10  w-full pt-20 md:pt-0">
          <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
            Live Demos <br />
            of Our Latest Innovations
          </h1>
          <p className="mt-4 font-normal text-base text-neutral-300 max-w-xl text-center mx-auto">
            Explore real-time insights and interactive experiences showcasing
            our cutting-edge projects, designed to highlight our expertise and
            transformative impact.
          </p>
        </div>
      </div>

      <div className="w-full pb-12 flex md:items-center md:justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
        <div className=" w-6/12 relative rounded-3xl overflow-hidden max-w-md mx-auto bg-gradient-to-r from-[#1D2235] to-[#121318] p-8 mb-10">
          <Rays />
          <Beams />
          <div className="relative z-10">
            <Lens hovering={hovering} setHovering={setHovering}>
              <Image
                src={ProjectImages.BMS}
                alt="image"
                style={{ width: '500px', height: '300px', objectFit: 'cover' }}
                className="rounded-2xl"
                preview={false}
              />
            </Lens>
            <motion.div
              animate={{
                filter: hovering ? 'blur(2px)' : 'blur(0px)',
              }}
              className="py-4 relative z-20"
            >
              <h2 className="text-white text-2xl text-left font-bold">
                BMS Monitoring of the cells
              </h2>
              <p className="text-neutral-200 text-left text-xl mt-4 h-11">
                Real-time monitoring with key metrics <br />
                like
                <FlipWords words={words} className="text-white" />.
              </p>
            </motion.div>
          </div>
        </div>
        <div className=" w-6/12 relative rounded-3xl overflow-hidden max-w-md mx-auto bg-gradient-to-r from-[#1D2235] to-[#121318] p-8 mb-10">
          <Rays />
          <Beams />
          <div className="relative z-10">
            <Lens hovering={hovering2} setHovering={setHovering2}>
              <Image
                src={ProjectImages.HomeAutomation}
                alt="image"
                style={{ width: '500px', height: '300px', objectFit: 'cover' }}
                className="rounded-2xl"
                preview={false}
              />
            </Lens>
            <motion.div
              animate={{
                filter: hovering2 ? 'blur(2px)' : 'blur(0px)',
              }}
              className="py-4 relative z-20"
            >
              <h2 className="text-white text-2xl text-left font-bold">
                Home Automation System
              </h2>
              <p className="text-neutral-200 text-left text-xl mt-4 h-11">
                Enabling real-time insights and control with parameters like
                <FlipWords words={words2} className="text-white" />.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
